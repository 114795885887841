<template>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-info">
                <div class="card-header">
                    <h3 class="card-title">Busqueda artículos</h3>
                </div>
                <div class="card-body">
                    <input type="text" placeholder="Busqueda articulo" @keyup.enter="cargararticulos()"
                        v-model="busquedaarticulo" class="form-control input-sm">

                    <table class="table">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Descripción</th>
                                <th>Precio</th>
                                <th>Coste</th>
                                <th>Proveedor</th>
                                <th>Pref.</th>
                                <th>Cant.</th>
                                <th>Almacén</th>
                                <th>Stock</th>
                                <th>Reserv.</th>
                                <th>Seleccionar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="articulo in articulosfiltrados" :key="articulo">
                                <td>{{articulo.codigo}}</td>
                                <td>{{ articulo.descripcion}}</td>
                                <td>{{ articulo.precio}}</td>
                                <td>{{ articulo.coste}}</td>
                                <td>{{ articulo.proveedor}}</td>
                                <td v-if="articulo.preferente==1">Si</td>
                                <td v-else>No</td>
                                <td>
                                    <InputNumber v-model="articulo.cantidad" :min="1" style="width: 3 rem"
                                        inputId="minmax-buttons" mode="decimal" showButtons />
                                </td>
                                <td>{{ articulo.almacen}}</td>
                                <td>{{ articulo.stock}}</td>
                                <td>{{ articulo.stock_reservado}}</td>
                                <td style="text-align:center"><button type="button"
                                        @click="seleccionararticulo(articulo)"
                                        class="btn btn-success btn-xs">Añadir</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">Artículos seleccionados</h3>
                </div>
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Código</th>
                                <th>Descripción</th>
                                <th>Precio</th>
                                <th>Coste</th>
                                <th>Proveedor</th>
                                <th>Cantidad</th>
                                <th>Almacén</th>
                                <th>Sección</th>
                                <th>Eliminar</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="articulotemporal in articulostemporales" :key="articulotemporal">
                                <td>{{ articulotemporal.articulos[0].codigo }}</td>
                                <td>{{ articulotemporal.articulos[0].descripcion }}</td>
                                <td>{{ articulotemporal.articulos[0].precio_venta }}</td>
                                <td>{{ articulotemporal.articulos[0].precio_unitario }}</td>
                                <td>{{ articulotemporal.proveedor }}</td>
                                <td>{{ articulotemporal.articulos[0].cantidad }}</td>
                                <td>
                                    <Dropdown v-model="articulotemporal.almacen"
                                        @change="modificaralmacen(articulotemporal)" :options="almacenes"
                                        placeholder="Selecciona almacén">
                                    </Dropdown>
                                </td>
                                <td>
                                    <InputNumber v-model="articulotemporal.articulos[0].seccion" style="width: 3 rem"
                                        inputId="minmax-buttons" mode="decimal" showButtons />
                                </td>
                                <td><button type="button"
                                        @click="eliminararticulo(articulotemporal.articulos[0].n_fila)"
                                        class="btn btn-danger btn-xs">Eliminar</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card-success">
                <div class="card-header">
                    <h3 class="card-title">Pedido(s)</h3>
                </div>
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Fecha</th>
                                <th>Proveedor</th>
                                <th>Descripción</th>
                                <th>Fecha entrega</th>
                                <th>Almacén</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="articulotemporal in articulostemporales" :key="articulotemporal">
                                <td>{{ new Date(articulotemporal.articulos[0].fecha).toLocaleDateString('es-ES') }}</td>
                                <td>{{ articulotemporal.proveedor }}</td>
                                <td>
                                    <Textarea type="text" v-model="articulotemporal.descripcion2" placeholder="Introduce nota a enviar al proveedor" />
                                </td>
                                <td>
                                    <Calendar v-model="articulotemporal.fechaentrega" id="hora" hourFormat="24" dateFormat="dd/mm/yy" />
                                </td>
                                <td>{{ articulotemporal.almacen }}</td>
                                <td>{{ articulotemporal.total }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <Button label="Generar" @click="generarpedidos()" style="width:100%">
            </Button>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
export default {
    props: ['id'],
    components: {
        InputNumber, Calendar, Dropdown, Textarea
    },
    data() {
        return {
            busquedaarticulo: '',
            articulos: '',
            articulosfiltrados: [],
            articulosseleccionados: [],
            almacenes: [],
            almacenseleccionado: '',
            numfila: 1,
            articulostemporales: [],
            indicesalmacen: [],
        }
    },
    methods: {
        async cargararticulos() {
            const api = new PwgsApi();
            this.articulosseleccionados = [];
            let aux = '';
            let subidadatos = { busqueda: this.busquedaarticulo };
            try{
                aux = await api.post('compras-pwgs/' + this.$props.id + '/busqueda-articulo', subidadatos);
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            this.articulos = aux.datos;
            for (let i = 0; i < this.articulos.length; i++){
                this.articulos[i].cantidad = 1;
            }
            
            this.articulosfiltrados = this.articulos;
        },
     
        async cargaralmacenes() {
            const api = new PwgsApi();
            let aux = await api.get('compras-almacenes');
            for (let auxi in aux) {
                let datos = { nombre: aux[auxi].nombre ,indice:auxi};
                this.almacenes.push(aux[auxi].nombre);
                this.indicesalmacen.push(datos);
            }

            },
        filtrararticulos() {
            this.articulosfiltrados = this.articulos;
     
        },
        async seleccionararticulo(articulo) {
            // POST: /compras-pwgs/:id_servicio/seleccion-articulo-pedido 
            const api = new PwgsApi();
            let subidadatos = { n_fila: this.numfila, id_articulo: articulo.id_articulo, cantidad: articulo.cantidad }
            await api.post('compras-pwgs/' + this.$props.id + '/seleccion-articulo-pedido', subidadatos);
            this.numfila++;
            this.listararticulostemporales();
        },
       async modificaralmacen(articulo) {
           // PUT: /compras-pwgs/:id_servicio/seleccion-articulo-pedido 
           let auxalmacen;
           for (let i = 0; i < this.indicesalmacen.length; i++){
               if (this.indicesalmacen[i].nombre == articulo.almacen)
               {
                   auxalmacen = this.indicesalmacen[i].indice;
                }
           }
           const api = new PwgsApi();
           let subidadatos = { n_fila: articulo.articulos[0].n_fila, id_almacen: auxalmacen, seccion: articulo.articulos[0].seccion };
           await api.put('compras-pwgs/' + this.$props.id + '/seleccion-articulo-pedido', subidadatos);
           this.listararticulostemporales();
        },
        async listararticulostemporales() {
            //GET: /compras-pwgs/:id_servicio/pedido-temporal
            const api = new PwgsApi();
            this.articulostemporales = await api.get('compras-pwgs/' + this.$props.id + '/pedido-temporal');
            this.numfila = this.articulostemporales.length + 1;
        },
        async eliminararticulo(fila) {
            //DELETE: /compras-pwgs/:id_servicio/seleccion-articulo-pedido
            const api = new PwgsApi();
            let subidadatos = { n_fila: fila }
            await api.deletebody('compras-pwgs/' + this.$props.id + '/seleccion-articulo-pedido', subidadatos);
            this.listararticulostemporales();
        },
        async generarpedidos() { 
            //POST: /compras-pwgs/:id_servicio/crear-pedido
            const api = new PwgsApi();
            let subidadatosaux = {};
            for (let i = 0; i < this.articulostemporales.length; i++) {
                 let aux = { n_fila: this.articulostemporales[i].articulos[0].n_fila, fecha_limite: new Date(this.articulostemporales[i].fechaentrega).toLocaleDateString('es-ES'), descripcion: this.articulostemporales[i].descripcion2 };
                 subidadatosaux[this.articulostemporales[i].id_proveedor] = aux;
            }
            let subidadatos = { pedidos: subidadatosaux };
            await api.post('compras-pwgs/' + this.$props.id + '/crear-pedido', subidadatos);
            this.listararticulostemporales();
            this.$parent.recargarpedidos();
        },
        },
    
    watch:
    {
        id() {
            this.articulosseleccionados = [];
        },     
    },
    mounted() {
        this.cargaralmacenes();
        this.listararticulostemporales();
    },
}
</script>
<style>
.p-inputnumber-buttons-stacked .p-inputnumber-input{
    width: 3rem;
}
.p-calendar{
    width: 8rem;
}
</style>